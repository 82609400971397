import React from 'react';

import HtmlRenderer from '../../../components/common/Html';

import MagicLink from '../../../components/link';
import { Imgix } from '../../../utils';

import {
  Wrapper,
  ItemWrapper,
} from './Content.styles';

const leftImageResponsive = {
  xxs: 6,
  md: 3,
  contained: true
};

const ContentItem = ({
  header,
  text,
  logo,
  logo_height,
  image,
  link,
  link_text
}) => {
  return (
    <ItemWrapper>
      <div className="block">
        <MagicLink {...link} >
          <Imgix
            {...image}
            sizes={leftImageResponsive}
            className="illustration"
          />
        </MagicLink>
        <div className="content">
          {logo?.url ? (
            <Imgix
              {...logo}
              height={logo_height || 60}
              className="logo"
              imgStyle={{
                objectFit: 'contain'
              }}
            />
          ): null}
          <HtmlRenderer
            className="text"
            html={header?.html}
          />
          <HtmlRenderer
            className="text h-mobile"
            html={text?.html}
          />
          {!!link?.url && (
            <MagicLink
              {...link}
              className="simple-link red  h-mobile"
            >
              {link_text}
            </MagicLink>
          )}
        </div>
      </div>
      <div className="mobile-content mobile-only">
        <HtmlRenderer
          className="text"
          html={text?.html}
        />
        {!!link?.url && (
          <MagicLink
            {...link}
            className="simple-link red"
          >
            {link_text}
          </MagicLink>
        )}
      </div>
    </ItemWrapper>
  );
}

const Content = ({
  title,
  items = []
}) => {
  return (
    <Wrapper>
      <div className="container">
        {!!title && <h2 className="section-subtitle">{title}</h2>}
        <div className="grid">
          {items.map((item, idx) => (
            <ContentItem
              key={idx}
              {...item}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

export default Content;
