import React from 'react';

import MagicLink from '../../../components/link';
import { nl2br } from '../../../utils';

import {
  Wrapper
} from './Footer.styles';

const Footer = (props) => {
  const {
    title,
    link,
    linkName
  } = props;

  return (
    <Wrapper>
      <div
        className="content container"
      >
        <p className="desc">{nl2br(title)}</p>
        <div className="links">
          {console.log(link)}
          {!!link?.url && (
            <MagicLink
              {...link}
              className="simple-link red"
            >
              {linkName}
            </MagicLink>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default Footer;
