import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

import PartnerScreen from '../screens/page/partner';
import { regroup } from '../utils';
import usePreviewData from '../utils/usePreviewData';

const PageTemplate = ({ data: rawData, pageContext }) => {
  const data = usePreviewData(rawData);
  console.log('*** context', pageContext);
  const page = regroup(data?.prismicPartnerPage?.data);
  page.title = page.title?.text;

  console.log('*** page ', page);

  return (
    <>
      <Seo
        title={page.title}
        description={page?.header?.text}
        image={page?.header?.cover?.url}
      />
      <PartnerScreen
        page={page}
      />
    </>
  );
};

export const query = graphql`
  query(
    $prismic: ID!
  ) {
    prismicPartnerPage(
      prismicId: { eq: $prismic },
    ) {
      data {
        title {
          text
        }
        logos {
          link {
            url
            target
          }
          logo {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
        header_text
        header_title
        footer_title
        footer_link_name
        content_title
        content_items {
          link_text
          image {
            alt
            dimensions {
              height
              width
            }
            url
          }
          link {
            url
            target
          }
          logo {
            alt
            dimensions {
              height
              width
            }
            url
          }
          logo_height
          header {
            html
          }
          text {
            html
          }
        }
        footer_link {
          url
          target
        }
        header_cover {
          alt
          dimensions {
            height
            width
          }
          url
        }
      }
    }
  }
`;
export default PageTemplate;