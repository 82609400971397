import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.nav`
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px 30px 5px 5px;
  background-color: black;
  color: white;
  transform: skew(-15deg);

  &.with-sub {
    ul {
      grid-template-columns: min-content max-content max-content auto;
    }
  }

  ul {
    display: grid;
    grid-template-columns: min-content max-content auto;
    column-gap: 7px;
    width: 100%;
    margin: 0px;
    padding: 3px 15px;
    color: white;
    transform: skew(15deg);
    overflow: hidden;
    text-overflow: ellipsis;

    li {
      list-style-type: none;
      text-transform: uppercase;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    li > a,
    li > span {
      color: white;
    }

    li > span {
      white-space: nowrap;
    }

    .item {
      color: #fff;
      font-size: 12px;
      hyphens: auto;
    }

    .sep {
      filter: invert(1);
      margin: 0 0 0 7px;
    }

    .bold {
      font-weight: 500;
    }

    .home {
      padding-top: 2px;
    }
  }

  @media ${device.mobileL} {
    width: max-content;
    padding: 3px 5px;
  }
`;
