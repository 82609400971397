import React from 'react';
import { Imgix, nl2br } from '../../../utils';

import {
  Wrapper,
  Content
} from './Cover.styles';

const Cover = (props) => {
  const {
    title,
    text,
    cover,
  } = props;
  const content = (className) => (
    <div className={className}>
      <p>{text}</p>
    </div>
  );

  return (
    <Wrapper>
      <div style={{ position: 'relative' }}>
        <Imgix
          {...cover}
          sizes="100vw"
          className="cover"
        />
        <div className="container greyzone">
          <Content>
            <div className="shape" />
            <div className="text">
              <h1 className="section-title">{nl2br(title)}</h1>
              {content('h-mobile')}
            </div>
          </Content>
        </div>
      </div>
      <div className="container mobile-only">
        {content()}
      </div>
    </Wrapper>
  );
}

export default Cover;
