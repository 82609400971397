import styled from 'styled-components';

// import { device } from '../../../utils';

export const Wrapper = styled.section`
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;

  .content {
    .links {
      margin-top: 20px;
    }
  }

  p.desc {
    font-size: 25px;
    max-width: 400px;
    margin: 20px auto 0 auto;
  }
`;