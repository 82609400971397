import React from 'react';

import Header from './Header';
import Cover from './Cover';
import Footer from './Footer';
import Content from './Content';

import { Partners } from '../../../components/common';


import { Wrapper } from './Page.styles';

const Page = ({ page }) => {
  return (
    <Wrapper>
      <Header
        title={page.title}
      />
      <Cover
        {...page.header}
      />
      {page?.logos?.length ? (
        <Partners
          partners={page?.logos}
        />
      ): null}
      <Content
        {...page.content}
      />
      <Footer
        {...page.footer}
      />
    </Wrapper>
  );
}

export default Page;
