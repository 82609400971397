import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.section`
  margin-top: 50px;
  margin-bottom: 20px;
  
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 70px 40px;

    @media ${device.laptop} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const ItemWrapper = styled.div`
  .block {
    background-color: #fafafa;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;
  }

  .illustration {
    height: 100%;
  }
  
  .image.logo {
    margin: 15px 0;
    max-width: 100%;
  }

  .content {
    padding: 30px 20px;

    @media ${device.tablet} {
      padding: 40px 30px;
    }
  }
  .text {
    line-height: 1.5em;
    &, p {
      font-size: 14px;
      text-align: left;
    }

    p {
      margin: 7px 0;
    }

    h1, h2, h3 {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0 0 15px 0;
      line-height: 1em;

      @media ${device.tablet} {
        font-size: 30px;
      }

    }

    ul {
      padding-left: 15px;
    }
  }

  .simple-link {
    display: inline-block;
    margin-top: 15px;
  }
`;