import React from 'react';

import { BreadcrumbSnippet } from '../seo';

import { Icon } from '../icons';
import HomeIcon from '../../icons/Home.svg';
import ArrowIcon from '../../icons/Next.svg';

import {
  getUrl,
  getCategoryUrl,
  getProductUrl,
  getArticleUrl,
  getStoriesUrl,
} from '../../utils';

import { Wrapper } from './Breadcrumb.styles';
import { useLocale } from '../../services/i18n/useLocale';
import MagicLink from '../link';

const Breadcrumb = ({ list = [] }) => {
  const intl = useLocale();
  return (
    <Wrapper aria-label="Breadcrumb" className={list?.length >= 3 && 'with-sub'}>
      <BreadcrumbSnippet list={list} />

      <ul>
        <li className="home">
          <MagicLink
            className="item"
            href={getUrl('/', intl.locale)}
            aria-label="Home"
          >
            <Icon src={HomeIcon} height={14} />
          </MagicLink>
        </li>
        {list.map((item, idx) => {
          const isLast = idx + 1 === list.length;
          return (
            <li key={item.url}>
              {isLast ? (
                <span className="item bold">{item.name}</span>
              ) : (
                <>
                  <MagicLink href={item.url} className="item">
                    {item.name}
                  </MagicLink>
                  <Icon src={ArrowIcon} height={7} className="sep" />
                </>
              )}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export const EshopBreadcrumb = ({ category, subCategory, product }) => {
  const intl = useLocale();
  const list = [];

  if (category) {
    list.push({
      url: getCategoryUrl(category.handle, intl.locale),
      name: category.title,
    });
  }

  if (subCategory) {
    list.push({
      url: getCategoryUrl(subCategory.handle, intl.locale),
      name: subCategory.title,
    });
  }

  if (product) {
    list.push({
      url: getProductUrl(product.handle, intl.locale),
      name: product.title,
    });
  }
  return <Breadcrumb className={!!subCategory && 'with-sub'} list={list} />;
};

export const TutoBreadcrumb = ({ page }) => {
  const intl = useLocale();
  const list = [];
  if (page.tags?.indexOf('tuto') !== -1) {
    list.push({
      url: getUrl('tutos', intl.locale),
      name: 'Tutos',
    });
  } else if (page.tags?.indexOf('tips') !== -1) {
    list.push({
      url: getUrl('astuces-et-conseils', intl.locale),
      name: 'Astuces et conseils',
    });
  } else if (page.tags?.indexOf('gamme') !== -1) {
    list.push({
      url: getUrl('gammes-phares', intl.locale),
      name: 'Nos gammes phares',
    });
  } else if (page.tags?.indexOf('actu') !== -1) {
    list.push({
      url: getArticleUrl('', intl.locale),
      name: 'Articles',
    });
  }

  if (page) {
    list.push({
      url: getUrl(page.uid, intl.locale),
      name: page?.title,
    });
  }
  return <Breadcrumb list={list} />;
};

export const ArticlesBreadcrumb = ({ article }) => {
  const intl = useLocale();
  const list = [];

  list.push({
    url: getArticleUrl('', intl.locale),
    name: 'Articles',
  });

  if (article) {
    list.push({
      url: getArticleUrl(article.uid, intl.locale),
      name: article?.title,
    });
  }
  return <Breadcrumb list={list} />;
};

export const StoriesBreadcrumb = ({ story }) => {
  const intl = useLocale();
  const list = [];

  list.push({
    url: getStoriesUrl('', intl.locale),
    name: 'Stories',
  });

  if (story) {
    list.push({
      url: getStoriesUrl(story.uid, intl.locale),
      name: story?.title,
    });
  }

  return <Breadcrumb list={list} />;
};

export default Breadcrumb;
