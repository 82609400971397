import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.header`
  padding: 30px 0 15px 0;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.tablet} {
      flex-direction: row;
      align-items: center;
    }
  }

  .subhead {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }


  @media ${device.desktop} {
    h1 {
      font-size: 20px;
    }
  }
`;