import React from 'react';

import {
  Wrapper
} from './Header.styles';

const Header = ({
  title,
}) => {
  return (
    <Wrapper>
      <div className="container">
        <div className="subhead">{title}</div>
      </div>
    </Wrapper>
  );
}

export default Header;
