import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.article`
  width: 100%;
  color: #111111;

  .section-title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0px 25px 0;

    @media ${device.laptop} {
      font-size: 35px;
    }
  }

  .section-subtitle {
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0px 25px 0;

    @media ${device.laptop} {
      font-size: 25px;
    }
  }

  p {
    font-size: 12px;

    @media ${device.laptop} {
      font-size: 14px;
    }
  }
`;
